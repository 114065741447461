@font-face {
  font-family: 'Striverx';
  src: url(/static/media/striverx.8efc03a1.eot); /* IE9 Compat Modes */
  src: url(/static/media/striverx.8efc03a1.eot?#iefix) format('embedded-opentype'), 
       url(/static/media/striverx.075f23de.woff2) format('woff2'), 
       url(/static/media/striverx.2b14beb9.woff) format('woff'), 
       url(/static/media/striverx.f18e1820.ttf)  format('truetype'), 
       url(/static/media/striverx.b27de30f.svg#svgFontName) format('svg'); /* Legacy iOS */
  font-weight: normal;
  font-style: normal;
}


