@font-face {
  font-family: 'Striverx';
  src: url('./static//fonts/striverx.eot'); /* IE9 Compat Modes */
  src: url('./static//fonts/striverx.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./static//fonts/striverx.woff2') format('woff2'), /* Super Modern Browsers */
       url('./static//fonts/striverx.woff') format('woff'), /* Pretty Modern Browsers */
       url('./static//fonts/striverx.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('./static//fonts/striverx.svg#svgFontName') format('svg'); /* Legacy iOS */
  font-weight: normal;
  font-style: normal;
}

